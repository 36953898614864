<template>
  <li class="nav-item nav-category">{{ title }}</li>
</template>

<script>
export default {
  name: 'SidebarCategory',
  props: ['title'],
};
</script>
